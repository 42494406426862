/**
 * Controller class for navigation menu
 */
//require('jquery');

export default class MenuController {

    constructor(selector, options) {
        const defaultOptions = {
            collapsedCls:       'docs-toc__item--collapsed',
            expandedCls:        'docs-toc__item--expanded',
            expandableCls:      'docs-toc__item--expandable',
            expandTriggerCls:   'docs-toc__expand-trigger'
        };

        this.options = Object.assign({}, defaultOptions, options);

        this.rootEl = document.querySelector(selector);
        if (!this.rootEl) {
            throw "Can't initialize navigation menu controller: Selector '" + selector + "' did not return any elements.";
        }
        this.init();
    }

    init() {
        this.initializeExpandCollapse();
    }

    initializeExpandCollapse() {
        const expandableItemSelector = "." + this.options.expandableCls;
        const expandTriggerSelector = expandableItemSelector + ' > a::before';

        const items = this.rootEl.querySelectorAll('.' + this.options.expandTriggerCls);
        console.log(expandTriggerSelector, items);
        items.forEach((item) => {
            item.addEventListener('click', (event) => {
                item.parentNode.classList.toggle(this.options.collapsedCls);
                console.log('click', item.classList);

                event.preventDefault();
                event.stopPropagation();

            });
            console.log(item);
        })
    }
}
